<template>
  <div>
    <TitleWrapper
      :filter-option="true"
      title="S_NOTIFIER"
      tooltip-title="S_NOTIFIER"
      :display-breadcrumb="true"
      :displayed-column-options="csvFilterOptions"
      :already-selected-displayed-column="filtersPayload.displayedColumns"
      :count="filtersCount"
      @cancel="cancelHanlder"
      @applyFilter="emitFiltersPayload($event, invalid)"
      @clear="clearFilters"
    >
      <template v-slot:filterItems>
        <InputFieldWrapper layout-mode="true">
          <UiInput
            v-model.trim="filtersPayload.title"
            class="flex-1"
            title="Title"
            type="text"
            placeholder="TITLE"
            :filter="FILTERS_LIST.TITLE"
            @emitQuery="updateQuery"
          />
          <UiMultiSelectBox
            v-model="filtersPayload.receiver_role_id"
            label="title"
            reduce="value"
            :options="UserRolesList"
            title="ROLE"
            :already-selected="selectedRoles"
            class="flex-1 mb-5 sm:mb-0"
            :filter="{
              option: FILTER_KEYS.IN,
              key: 'receiver_role_id',
            }"
            input-color="bg-white"
            @emitQuery="updateQuery"
          />
          <UiMultiSelectBox
            v-model="filtersPayload.mediums"
            label="name"
            reduce="name"
            :options="NOTIFICATION_COMMUNICATION_MEDIUMS_LIST"
            title="MEDIUM"
            :already-selected="selectedMediums"
            class="flex-1 mb-5 sm:mb-0"
            :filter="{
              option: FILTER_KEYS.IN,
              key: 'mediums',
            }"
            input-color="bg-white"
            @emitQuery="updateQuery"
          />
          <UiDatePicker
            v-model.trim="filtersPayload.inserted_at"
            title="DATE"
            :class="$style.widthItems"
            class="flex-1"
            :filter="{
              option: FILTER_KEYS.EQUAL,
              key: 'inserted_at',
            }"
            picker="date"
            @emitQuery="updateQuery"
          />
          <UiMultiSelectBox
            v-model.trim="filtersPayload.inserted_by_id"
            title="SENT_BY"
            label="full_name"
            :options="senderList"
            :already-selected="selectedSenders"
            class="flex-1"
            reduce="id"
            :image="true"
            input-color="bg-white"
            :search-through-api="true"
            :filter="{ option: FILTER_KEYS.IN, key: 'inserted_by_id' }"
            @emitQuery="updateQuery"
            @search="loadSenderList"
          />
        </InputFieldWrapper>
      </template>
    </TitleWrapper>
  </div>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import TitleWrapper from '@src/components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { getCountFromObject, removeEmptyKeysFromObject } from '@utils/generalUtil'
import { NOTIFICATION_COMMUNICATION_MEDIUMS_LIST } from '@src/constants/general-constants'
import { ROLES_LIST, TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UiMultiSelectBox from '@src/components/UiElements/UiMultiSelect.vue'
import filterMixins from '@/src/mixins/filter-mixins.js'
import { buildWhereQuery } from '@/src/utils/filters'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    TitleWrapper,
    UiDatePicker,
    InputFieldWrapper,
    UiMultiSelectBox,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      ROLES_LIST,
      NOTIFICATION_COMMUNICATION_MEDIUMS_LIST,
      FILTERS_LIST,
      FILTER_KEYS,
      $where: {},
      queries: {},
      senderList: [],
      UserRolesList: [],
      selectedSenders: [],
      selectedMediums: [],
      selectedRoles: [],
      appliedQueries: {},
      filtersCount: 0,
      title: 'title',
      filtersPayload: {
        title: '',
        mediums: [],
        inserted_at: '',
        receiver_role_id: [],
        inserted_by_id: [],
        displayedColumns: [],
      },
    }
  },
  computed: {
    ...mapState({
      campus: (state) => state.layout.currentCampusScope || null,
    }),
  },

  watch: {
    campus: {
      handler() {
        this.loadSenderList()
        this.clearFilters()
      },
    },
  },
  mounted() {
    this.loadSenderList()
    this.updateDisplayedColumnList()
    this.UserRolesList = this.ROLES_LIST.filter((role) =>
      [
        TENANT_ROLES.STAFF,
        TENANT_ROLES.SECTION_TEACHER,
        TENANT_ROLES.GUARDIAN,
        TENANT_ROLES.SECTION_STUDENT,
      ].includes(role.value),
    )
  },
  methods: {
    loadSenderList(query = '') {
      let alreadySelectedSenders = []
      const payload = { $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query) } }
      payload.$where = {
        ...payload.$where,
        ...buildWhereQuery(FILTER_KEYS.IN, 'role_ids', [
          TENANT_ROLES.SUPER_ADMIN,
          TENANT_ROLES.CAMPUS_ADMIN,
        ]),
        ...buildWhereQuery(FILTER_KEYS.EQUAL, 'campus_id', this.campus?.id),
      }
      removeEmptyKeysFromObject(payload.$where)
      this.getStaffWithFilters(payload).then((response) => {
        this.senderList = response?.data?.records
        if (response) {
          if (this.filtersPayload && this.filtersPayload.inserted_by_id.length) {
            this.filtersPayload.inserted_by_id.forEach((id) => {
              alreadySelectedSenders = this.senderList.filter((sender) => sender.id === id)
            })
          }
          this.selectedSenders = alreadySelectedSenders
        }
      })
    },
    getSelectedRoles() {
      let alreadySelectedRoles = []
      this.UserRolesList.forEach((role) => {
        if (this.filtersPayload && this.filtersPayload.receiver_role_id.length) {
          this.filtersPayload.receiver_role_id.forEach((value) => {
            if (role.value === value) alreadySelectedRoles.push(role)
          })
        }
        this.selectedRoles = alreadySelectedRoles
      })
    },
    getSelectedMediums() {
      let alreadySelectedMediums = []
      this.NOTIFICATION_COMMUNICATION_MEDIUMS_LIST.forEach((medium) => {
        if (this.filtersPayload && this.filtersPayload.mediums.length) {
          this.filtersPayload.mediums.forEach((value) => {
            if (medium.name === value) alreadySelectedMediums.push(medium)
          })
        }
        this.selectedMediums = alreadySelectedMediums
      })
    },
    getFiltersCount() {
      this.loadSenderList()
      this.getSelectedRoles()
      this.getSelectedMediums()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    ...mapActions('staff', ['getStaffWithFilters']),
    ...mapActions('teacher', ['getTeacherWithFilters']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
