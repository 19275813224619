<template>
  <div
    class="flex flex-row"
    @click="clickToolTip"
    @mouseover="displayToolTip"
    @mouseleave="hideToolTip"
  >
    <UiOnClickOutside :do="closeToolTip">
      <div class="absolute z-10" :class="dropdownTopBottom">
        <div v-if="showToolTip" class="">
          <Tooltip
            :text-extra-small="true"
            :tooltip-border="true"
            :campus="`Campus: ${notification.campus.title}`"
            :text="notification.class ? `Class: ${notification.class.title} ` : null"
            :description="notification.section ? `Section: (${notification.section.title}) ` : null"
            direction="bottom"
            :hide-icon="true"
            min-width="tooltip-shadow tooltip-width tooltip-padding tooltip-margin tooltip-border"
            :tooltip="tooltip"
          />
        </div>
      </div>
    </UiOnClickOutside>
    <div>
      <span v-if="notification.class">{{ notification.class.title }}</span>
      &nbsp;
      <span v-if="notification.section">{{ notification.section.title }}</span>
      <span v-if="!notification.class && !notification.section">
        Campus: {{ notification.campus && notification.campus.title }}
      </span>
    </div>
  </div>
</template>

<script>
import Tooltip from '@components/tooltip.vue'
import tooltipMixin from '@src/mixins/components/tooltip-mixin.js'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'

export default {
  components: {
    Tooltip,
    UiOnClickOutside,
  },
  mixins: [tooltipMixin],
  props: {
    notification: { type: Object, default: () => ({}) },
    dropdownTopBottom: {
      type: [Number, String],
      default: null,
    },
    tooltip: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      note: { class: '9', section: '(A)' },
    }
  },
}
</script>
