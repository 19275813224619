import AnnouncementData from '@/src/router/views/notifications/Announcement.json'
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = () => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
    },
  ]
  return stats
}

export const AnnouncementGraphChart = (BAR_GRAPH_VALUE, PIE_GRAPH_VALUE) => {
  const chartData = [
    GRAPH_CONSTANTS.BAR_CHART(
      AnnouncementData.BAR_LABELS,
      BAR_GRAPH_VALUE,
      AnnouncementData.BAR_TITAL,
      AnnouncementData.BAR_LABELS_STRING,
    ),
    GRAPH_CONSTANTS.PIE_CHART(
      AnnouncementData.PIE_LABELS,
      PIE_GRAPH_VALUE,
      AnnouncementData.PIE_BACKGROUND_COLOR,
      AnnouncementData.PIE_TITAL,
    ),
  ]
  return chartData
}
