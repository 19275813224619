<template>
  <div>
    <AnnouncementFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(notificationList)">
      <NoRecordFound />
    </div>
    <div v-else class="bg-white relative md:mx-0 mx-5 mt-5 border border-primary-grey rounded-lg">
      <div
        id="table-container"
        :class="[leftBlurArea, rightBlurArea]"
        class="overflow-x-auto rounded-lg"
        @scroll.passive="scrollDetect"
      >
        <table class="w-full table-auto whitespace-nowrap">
          <thead class="bg-bright-gray">
            <tr class="ltr:text-left">
              <th
                v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
                v-show="
                  showColumnInDataTable(filtersData, head.dataTableName, [
                    'Title',
                    'Role',
                    'Medium',
                    'N_S_BY',
                    'Date',
                  ])
                "
                :key="head.id"
                class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pl-0 rtl:pr-4 ltr:pl-4 ltr:pr-0"
              >
                <span
                  v-i18n="dashboard"
                  class="ltr:border-r rtl:border-l border-primary-grey w-full block rtl:pl-4 ltr:pr-5"
                >
                  {{ head.dataTableName }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(notification, index) in notificationList"
              :key="index"
              class="border-t border-primary-grey text-left"
            >
              <td
                v-show="showColumnInDataTable(filtersData, 'Title', ['Title'])"
                class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-0"
              >
                <span
                  class="font-roboto font-normal text-primary-green text-sm cursor-pointer"
                  @click="redirectToNotificationDetails(notification)"
                >
                  {{ notification.title }}
                </span>
              </td>
              <td
                v-show="showColumnInDataTable(filtersData, 'Type')"
                class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-0"
              >
                <span class="font-roboto font-normal text-text-color text-sm">
                  {{ notification?.announcement_type?.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </span>
              </td>
              <td
                v-show="showColumnInDataTable(filtersData, 'Role', ['Role'])"
                class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-0"
              >
                <span class="font-roboto font-normal text-text-color text-sm">
                  {{ notification.role }}
                </span>
              </td>
              <td
                v-show="showColumnInDataTable(filtersData, 'Medium', ['Medium'])"
                class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-5"
              >
                {{ notification.medium }}
                <span
                  v-for="(val, ind) in notification.mediums"
                  :key="ind"
                  class="font-roboto font-normal text-text-color text-sm"
                >
                  {{ val }}
                  <span v-if="ind + 1 < notification.mediums.length">,</span>
                </span>
              </td>
              <td
                v-show="showColumnInDataTable(filtersData, 'Role Context')"
                class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right"
              >
                <span class="font-roboto font-normal text-text-color text-sm">
                  <div class="rounded-xl p-3 relative">
                    <RoleContext
                      :notification="notification"
                      :dropdown-top-bottom="
                        index === notificationList.length - 1 ? 'bottom-10' : 'top-10'
                      "
                      :tooltip="
                        index === notificationList.length - 1 ? 'tooltip-bottom' : 'tooltip-top'
                      "
                    />
                  </div>
                </span>
              </td>
              <td
                v-show="showColumnInDataTable(filtersData, 'Total Receivers')"
                class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-0 text-center"
              >
                <span
                  class="cursor-pointer text-primary-green"
                  @click="redirectToNotificationDetails(notification)"
                >
                  {{ notification.stats.receivers_count }}
                </span>
              </td>
              <td
                v-show="showColumnInDataTable(filtersData, 'N_S_BY', ['N_S_BY'])"
                class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-0"
              >
                <span>
                  <SingleUserDisplay
                    :user="notification.inserted_by"
                    :image="true"
                    label="full_name"
                    :dropdown-top-bottom="
                      index === notificationList.length - 1 ? 'bottom-6' : 'top-6'
                    "
                    :tooltip="
                      index === notificationList.length - 1 ? 'tooltip-bottom' : 'tooltip-top'
                    "
                  />
                </span>
              </td>
              <td
                v-show="showColumnInDataTable(filtersData, 'Date', ['Date'])"
                class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-0"
              >
                <span class="font-roboto font-normal text-text-color text-sm">
                  {{ $filters.dateFormatInUTC(notification?.inserted_at, TIME_CONSTANTS.DATE) }}
                </span>
              </td>
              <td
                v-show="showColumnInDataTable(filtersData, 'Status')"
                class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-12"
              >
                <span class="font-roboto font-normal text-text-color text-sm">
                  <UiBadgeDisplay
                    :options="notification.mediumStatus"
                    label="title"
                    :dropdown-top-bottom="
                      dropdownTopBottom(index, notificationList, 'bottom-6', 'top-6')
                    "
                    :tooltip="
                      dropdownTopBottom(index, notificationList, 'tooltip-bottom', 'tooltip-top')
                    "
                  />
                </span>
              </td>
              <td
                v-show="showColumnInDataTable(filtersData, 'Actions')"
                class="border-primary-grey w-10 px-5"
              >
                <TableAction
                  :current-user="notification"
                  :idx="index"
                  :action-list="actionList"
                  :top="index === notificationList.length - 1 ? -75 : -12"
                  :drop-down="
                    index === notificationList.length - 1
                      ? 'tableDropDownbottom'
                      : 'tableDropDowntop'
                  "
                  :right="60"
                  :rtl-right="-245"
                  min-width="min-width-2"
                  @action="typeAction"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination
      v-if="totalNotificationCount > 10"
      v-show="!isLoading"
      :key="`ForceRender${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(totalNotificationCount, filteredRecordLimit)"
      @filterRecord="_getAnnouncements"
    />
    <SingleNotificationModal v-if="showModal" @updateResult="getNewResults" />
    <UIConfirmationModal
      v-if="confirmation"
      heading="RETRY_REQ"
      message="Are you sure you want to retry "
      button-text="Retry"
      :modal="confirmation"
      :name="currentAnnouncement.title"
      remaining-message="Request ?"
      @cancel="toggleConfirmationModal"
      @confirm="retryTheAnnouncementRequest"
    />
    <UIConfirmationModal
      v-if="resendModalConfirmation"
      :modal="resendModalConfirmation"
      heading="RESEND_REQ"
      message="Are you sure you want to resend "
      button-text="Resend"
      :name="currentAnnouncement.title"
      remaining-message="Request ?"
      @cancel="toggleResendConfirmationModal"
      @confirm="resendTheAnnouncementRequest"
    />
  </div>
</template>

<script>
import { dropdownTopBottom } from '@src/utils/generalUtil.js'
import isEmpty from 'lodash/isEmpty'
import RoleContext from '@views/notifications/RoleContext.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import TableAction from '@/src/components/TableAction.vue'
import { mapActions, mapState } from 'vuex'
import generalUtil from '@src/mixins/general-mixins.js'
import SingleNotificationModal from '@/src/router/views/admin/modals/AnnouncementUpsertModal.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import AnnouncementFilter from '@src/router/views/notifications/AnnouncementFilter.vue'
import {
  transformTextToCapitalize,
  objectDeepCopy,
  showColumnInDataTable,
} from '@utils/generalUtil'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import fileMixins from '@/src/mixins/file-mixins'
import { getTimeFromDateWithUTC } from '@utils/moment.util'
import TIME_CONSTANTS from '@src/constants/date-time-constants'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import scrollMixin from '@src/mixins/scroll-mixin'
import SingleUserDisplay from '@/src/components/UiElements/SingleUserDisplay.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { fullName } from '@/src/utils/settings/tenant-user.util'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import UiBadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import {
  AnnouncementGraphChart,
  rightBarStats,
} from '@/src/router/views/notifications/Announcement.util.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import AnnouncementData from '@/src/router/views/notifications/Announcement.json'

export default {
  components: {
    RoleContext,
    Loader,
    AnnouncementFilter,
    TableAction,
    SingleNotificationModal,
    Pagination,
    SingleUserDisplay,
    NoRecordFound,
    UIConfirmationModal,
    UiBadgeDisplay,
  },
  mixins: [generalUtil, fileMixins, scrollMixin],
  data() {
    return {
      TIME_CONSTANTS,
      GENERAL_CONSTANTS,
      isEmpty,
      result: 'result',
      title: 'title',
      dashboard: 'dashboard',
      options: ['Select'],
      defaultSelectedColumns: AnnouncementData.defaultSelectedColumns,
      tableHeaders: AnnouncementData.tableHeaders,
      forceRender: 0,
      totalNotificationCount: 0,
      notificationList: [],
      filteredRecordLimit: 10,
      isLoading: false,
      isHideNotify: false,
      filtersData: {},
      noRecord: false,
      actionList: [{ name: 'SNA_RET' }, { name: 'SNA_RES' }],
      confirmation: false,
      currentAnnouncement: null,
      resendModalConfirmation: false,
      announcementRetrySucessMessage: 'Job started for resending failed announcements',
    }
  },
  page: {
    title: 'Notification | Single',
    meta: [
      {
        name: 'description',
        content: 'Single Notifications',
      },
    ],
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      tabRightBar: (state) => state.layout.tabRightNav,
    }),
  },
  watch: {
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.setRightbar()
      },
    },
    currentCampusScope: {
      handler(val) {
        if (val) {
          this.getNewResults()
          this.setRightbar()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setRightbar()
    this._getAnnouncements()
  },
  methods: {
    dropdownTopBottom,
    showColumnInDataTable,
    getNewResults() {
      this._getAnnouncements()
      this.forceRender++
    },
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.getNewResults()
    },
    redirectToNotificationDetails(notification) {
      this.$router?.push({
        name: 'announcement-detail',
        params: {
          announcementId: notification.id,
        },
      })
      this.isHideNotify = true
      let statsPayload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'id', notification.id),
        },
      }
      this.getAnnouncementStats(statsPayload).then((res) => {
        this.setRightbar(res?.data)
      })
    },

    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
      if (!this.confirmation) this.currentAnnouncement = null
    },
    toggleResendConfirmationModal() {
      this.resendModalConfirmation = !this.resendModalConfirmation
      if (!this.resendModalConfirmation) this.currentAnnouncement = null
    },

    typeAction(action, currentAnnouncement, idx) {
      this.currentAnnouncement = currentAnnouncement
      switch (action) {
        case 'SNA_RET':
          this.toggleConfirmationModal()
          break
        case 'SNA_RES':
          this.toggleResendConfirmationModal()
          break
      }
    },

    retryTheAnnouncementRequest() {
      this.retryAnnouncementRequest(this.currentAnnouncement.id).then((res) => {
        this.confirmation = false
        this.currentAnnouncement = null
        this.$store.commit('toast/NEW', {
          message: res?.data?.message || this.announcementRetrySucessMessage,
          type: 'success',
        })
      })
    },

    resendTheAnnouncementRequest() {
      this.resendAnnouncementRequest(this.currentAnnouncement.id).then((res) => {
        this.resendModalConfirmation = false
        this.currentAnnouncement = null
        this._getAnnouncements()
        this.$store.commit('toast/NEW', {
          message: res?.data?.message || this.announcementRetrySucessMessage,
          type: 'success',
        })
      })
    },

    async _getAnnouncements(range) {
      if (!this.currentCampusScope) return
      const paginationLimit = {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload

      this.isLoading = true
      const [res, err] = await this.getAnnouncemnt(payload)

      this.isLoading = false
      if (err) return

      this.totalNotificationCount = res.data.meta.total_records
      this.getAnnouncementStats().then((res) => {
        this.setRightbar(res?.data)
      })
      this.notificationList = res.data.records.map((notification) => {
        notification.mediums = []
        if (notification.stats.total_email_count) {
          notification.mediums.push('email')
        }
        if (notification.stats.total_webpush_count) {
          notification.mediums.push('push notification')
        }
        if (notification.stats.total_sms_count) {
          notification.mediums.push('sms')
        }
        if (notification.announcement_receivers && notification.announcement_receivers.length) {
          notification.announcement_receivers.forEach((receiver) => {
            fullName(receiver)
          })
        }
        notification.role = transformTextToCapitalize(notification.receiver_role_id)
        notification.inserted_by.full_name = `${notification.inserted_by.first_name} ${notification.inserted_by.last_name}`
        let mediumsStats = notification.stats
        notification.mediumStatus = []
        if (mediumsStats.total_sms_count)
          notification.mediumStatus.push({
            title: `SMS :${mediumsStats.sms_sent_count} / ${mediumsStats.total_sms_count}`,
          })
        if (mediumsStats.total_email_count)
          notification.mediumStatus.push({
            title: `Email :${mediumsStats.email_sent_count} / ${mediumsStats.total_email_count}`,
          })
        if (mediumsStats.total_webpush_count)
          notification.mediumStatus.push({
            title: `Webpush :${mediumsStats.webpush_sent_count} / ${mediumsStats.total_webpush_count}`,
          })
        return notification
      })
    },

    setRightbar(stats = []) {
      let BAR_GRAPH_VALUE = [
        stats?.total_announcements,
        stats?.staff_announcements,
        stats?.teacher_announcements,
        stats?.student_announcements,
        stats?.guardian_announcements,
      ]
      let PIE_GRAPH_VALUE = [stats?.total_failed, stats?.total_sent]
      let rightBarContent = {
        header: {
          title: 'Notifications',
        },
        stats: rightBarStats(),
        chartData: AnnouncementGraphChart(BAR_GRAPH_VALUE, PIE_GRAPH_VALUE),
      }
      if (!this.isHideNotify) {
        rightBarContent.header.buttons = [
          {
            title: 'NOTIFY',
            classes: ['primary-button-right'],
            action: { name: 'layout/setShowModal' },
          },
        ]
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('notifications', [
      'getAnnouncemnt',
      'retryAnnouncementRequest',
      'getAnnouncementStats',
      'resendAnnouncementRequest',
    ]),
    ...mapActions('layout', ['setRightbarContent', 'setRightBarStats', 'setShowModal']),
  },
}
</script>

<style lang="scss" scoped>
.sent {
  color: #267c26;
  background: #e9f2e9;
}
.pending {
  color: #ffc201;
  background: #fff9e6;
}
.failed {
  color: #ea3535;
  background: #fce1e1;
}
</style>
